.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    margin: auto;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.modal-spinner {
    height: 10vh;
}