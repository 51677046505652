.permissions-editor .wrap-any {
    word-wrap: break-word;
}

.permissions-editor .p-dialog .p-dialog-content {
    width: 50vw
} 

@media only screen and (max-width: 600px) {
    .permissions-editor .p-dialog .p-dialog-content {
        width: 90vw
    }
}


.permissions-editor .fix-dropdown-width {
    min-width: 150px;
}

.permissions-editor .permissions-options {
    display: flex;
    gap: 0.5rem;
    
}



.permissions-editor .permissions-options .p-button {
    padding: 0.3rem 0.5rem;
}


/* separate */

.actions{
    display: flex;
    gap:0.5rem
}


.permissions-editor .p-datatable-footer .add-permission-btn .p-button {
    border-color: var(--primary-blue);
    background-color: var(--primary-blue);
}


.permissions-editor .p-datatable-footer .add-permission-btn .p-button:hover {
    border-color: var(--primary-red);
    background-color: var(--primary-red);
}




/* Delete Certificate Button */
.permissions-editor .p-datatable-footer .delete-certificate-button {
    background-color: var(--primary-red);
    border-color: var(--primary-red);
}

.permissions-editor .p-datatable-footer .delete-certificate-button:hover {
    background-color: var(--green-700);
    border-color: var(--green-700);
}


/* Dialog  */
/* 
.permissions-editor .p-dialog .p-dialog-content {
    min-height: 40vh;
    min-width: 50vw;
    max-width: 100vw;
}

.permissions-editor .p-dialog .p-dialog-header {
    background-color:#3f40d8 !important;
    color:white !important;
    width: 100%;
    height: 4rem;
}

.permissions-editor .p-dialog .p-dialog-header .p-dialog-title {
    font-size: 1.2em !important;
}

@media only screen and (max-width: 600px) {
    .permissions-editor .p-dialog .p-dialog-content {
       max-width: 90vw;
    }
}

.permissions-editor .select-container  {   
    margin: 0.5rem;
}

.permissions-editor .select-container .p-datatable-header .table-header-container {
    width: 50% !important;   
    margin: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.permissions-editor .select-container .p-datatable-header .table-header-container .breadcrumb {
    display: flex;
    flex-basis: 85%;
  }

.permissions-editor .select-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    
  }

.permissions-editor.select-container .react-select{
    width: 60%;
}

.permissions-editor .select-container .p-button{
    background-color:#3f40d8 !important;
    color:white !important;
}

.permissions-editor .datatable-empty-message {
   vertical-align: top !important;
   text-align: center !important;
   margin-top:2rem;
}
 */
