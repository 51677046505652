.title-wrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    font-family: var(--global-font);
}

.image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.logo {
    width: 280px;
    height: 3rem;
    object-fit: contain;
}

.title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
}

.title-container :global(.pi.pi-file-edit) {
    color: var(--primary-red);
}

.title-heading {
    color: #373737dd;
    font-size: 1.5rem;
}
