.admin-view-wrapper {
    width: 90%;
    height: fit-content;
    margin-top: 2rem;
}

.admin-view-wrapper.p-card .p-card-body, .admin-view-wrapper.p-card .p-card-content {
    padding: 0rem;
}

.admin-view-wrapper .p-tabview .p-tabview-panels {

    padding: 0rem;
}

.admin-view-wrapper .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: var(--primary-blue);
    color: var(--primary-blue);
}

.admin-view-wrapper .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: var(--primary-blue);
}

.p-tabview-nav-link {
    font-size: large;
}

.tabview-wrapper .p-tabview-nav-container {
    font-size: 1.25rem;
}