.add-tag-dialog {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.add-tag-dialog>div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem
}


.tag-definition-editor .wrap-any {
    word-wrap: break-word;
}

.tag-definition-editor .p-dialog .p-dialog-content {
    width: 50vw
}

@media only screen and (max-width: 600px) {
    .tag-definition-editor .p-dialog .p-dialog-content {
        width: 90vw
    }
}

.tag-definition-editor .fix-dropdown-width {
    min-width: 150px;
}

.tag-definition-editor .tag-definition-options {
    display: flex;
    gap: 0.5rem;
}

.tag-definition-editor .tag-definition-options .p-button {
    padding: 0.3rem 0.5rem;
}

.tag-dialog-footer .save-tag, .tag-dialog-footer .p-button.cancel-save-tag:hover,
.tag-definition-editor .p-datatable-footer .add-tag-btn .p-button {
    border-color: var(--primary-blue);
    background-color: var(--primary-blue);
}

.tag-dialog-footer .cancel-save-tag, .tag-dialog-footer .p-button.save-tag:hover,
.tag-definition-editor .p-datatable-footer .add-tag-btn .p-button:hover {
    border-color: var(--primary-red);
    background-color: var(--primary-red);
}