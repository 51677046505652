.button-card {
    display: flex;
    justify-content: flex-end;
}

:global(.p-card) .button-card {

    box-shadow: none;

}

:global(.p-card) .button-card :global(.p-card-body),
:global(.p-card) .button-card :global(.p-card-content) {

    padding: 0;


}

.button-card :global(.p-card-content) {
    position: relative;

}

.button-card :global(.p-card-content) button:hover {

    background-color: var(--primary-red);
    border-color: var(--primary-red);
}

.button-card :global(.p-card-body.p-body),
.button-card :global(.p-card-content) {
    padding: 1px;
}

.button-card :global(.p-button) {
    background-color: var(--primary-blue);
}