.login-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
}

.login-form-card {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 12rem;
}

.login-form .p-component {
    font-size: large;
    font-family: var(--global-font);
}

.login-form-card .p-inputtext:enabled:hover {
    border-color: var(--primary-red);
}

.login-form-card .p-inputtext:enabled:focus {
    box-shadow: 0 0 0 0.1rem rgba(252, 29, 0, 0.788);
    border-color: orange;
}

.login-form-card.p-card {
    box-shadow: 0px -3px 3px -3px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.2),
        0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.login-form .p-button-raised {
    background-color: var(--primary-blue);
}

.login-form .p-button-raised .p-button-label {
    font-weight: 400;
}

.support-message {
    margin-top: 2.5rem;
    text-align: center;
    font-size: 1.5rem;
    color: var(--primary-blue);
    position: fixed;
    top: 28rem;
}

.highlight-lab {
    color: var(--primary-red);
    font-weight: bold;
}

.login-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
