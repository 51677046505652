.certificate-data-input-wrapper {

    display: grid;
    gap: 2rem;
    grid-template-areas: "1fr 1fr";
    justify-content: center;

}

.certificate-data-input-wrapper span {
    height: auto;
    grid-row: "templateKeys";
}

.certificate-dialog-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
}

.certificate-dialog-content .certificate-dialog-actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}


.certificate-dialog-content .certificate-dialog-actions .p-button {
    background-color: var(--primary-blue);
    border-color: var(--primary-blue);
}

.certificate-dialog-content .certificate-dialog-actions .p-button:enabled:active,
.certificate-dialog-content .certificate-dialog-actions .p-button:enabled:hover,
.certificate-dialog-content .certificate-dialog-actions .p-button.p-button-text:enabled:hover {
    background-color: transparent;
    color: var(--primary-blue);
    border-color: var(--primary-blue);
}



.certificate-dialog-content .certificate-dialog-actions .p-button.p-component.p-button-text {

    color: var(--primary-blue);
    background-color: transparent;
    border-color: transparent;
}

.certificate-dialog-input.p-float-label input:focus~label {

    font-size: 0.9rem;
    font-weight: bold;
}

.certificate-dialog-input .p-inputtext.p-component {
    width: 100%;
}

.template-select-dropdown {

    justify-content: center;
    width: 100%;
    grid-area: "select";
}