.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  height: 100%;
}

.app-container .p-button {
  padding: 0.5rem 1rem;
}