:root {
  --global-font: "Futura", "sans-serif";
  --primary-blue: #423fdb;
  --primary-red: #fe482b;
}

#root {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;

}

body {
  margin: 0;
  font-family: var(--global-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body>iframe {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* body .p-component{
  font-size: 14px;
  font-family: var(--global-font) !important;
} */


.p-component, .p-inputtext {
  font-family: var(--global-font) !important;
}

body .p-dialog,
body .p-dialog .p-dialog-header {
  border-top-right-radius: .5em;
  border-top-left-radius: .5em;
}

body .p-dialog .p-dialog-header {
  background-color: var(--primary-blue);
  border: 1px solid var(--primary-blue);
  color: white;
}

body .p-dialog .p-dialog-header .p-dialog-header-icon {
  color: white;
  font-size: 1.5em;
}

body .p-link:focus {
  box-shadow: none;
}

body .p-dialog,
body .p-dialog .p-dialog-footer {
  border-bottom-right-radius: .5em;
  border-bottom-left-radius: .5em;
}