:global(.p-dialog).warning-message-dialog :global(.p-dialog-content) {
    padding: 0rem 1rem 2rem 1rem;

}

:global(.p-dialog).warning-message-dialog :global(.p-dialog-header) {
    padding: 0.5rem 0.5rem 0rem 0rem;
}

.warning-message {
    text-align: center;
    line-height: 1.5rem;
    font-size: 1.2rem;
}

.warning-message-container {
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}


.warning-message-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}