/* .confirmation-dialog {
  min-width: 15vw;
  max-width: 70vw;
} */

@media only screen and (max-width: 600px) {
    .confirmation-dialog {
        max-width: 90vw;
    }
}

.confirmation-dialog .p-dialog-content {
    /* min-width: 15vw; */
    /* max-width: 70vw; */
    padding: 0.5em;
    overflow-y: auto;
}

.confirmation-dialog .bold-text {
    font-weight: bold;
}

.confirmation-dialog .confirmation-button-div {
    display: inline-block;
    text-align: left;
    /* width: '50%' */
}

.confirmation-dialog .cancel-button-div {
    display: inline-block;
    text-align: right;
    /* width: '50%' */
}

/* .confirmation-dialog .confirmation-button{
  margin: 0 1em !important;
}

.confirmation-dialog .cancel-button{
  margin: 0 1em !important;
} */

.confirmation-dialog-no-titlebar .p-dialog-titlebar {
    display: none;
}


.confirmation-dialog .p-button.confirmation-button, .confirmation-dialog .p-button.p-button-info.cancel-button:hover {
    border-color: var(--primary-blue);
    background-color: var(--primary-blue);
}

.confirmation-dialog .p-button.cancel-button, .confirmation-dialog .p-button.p-button-danger.confirmation-button:hover {
    border-color: var(--primary-red);
    background-color: var(--primary-red);
}

.confirmation-dialog .p-dialog-header, .confirmation-dialog .p-dialog-content, .confirmation-dialog .p-dialog-footer {
    padding: 1rem;
}