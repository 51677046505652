.add-certificate-dialog {
    width: 30%;
}

.certificates-datatable {}


/* buttons */

.certificates-header .action-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
}

.certificates-header .action-buttons-wrapper .p-card button:hover {
    background-color: var(--primary-blue);
    border-color: var(--primary-blue)
}



.certificates-header .action-buttons-wrapper .refresh-certificates-button {

    background-color: var(--primary-blue);
    border: var(--primary-blue);
    padding: 0.5rem;
}

.certificates-header .action-buttons-wrapper .refresh-certificates-button:hover {

    background-color: var(--primary-red);
    border: var(--primary-red);
}


.certificates-header .action-buttons-wrapper div:nth-child(1) button {
    background-color: var(--primary-blue);
    border: var(--primary-blue);

}

.certificates-header .action-buttons-wrapper div:nth-child(2) button {
    background-color: var(--green-700);
    border-color: var(--green-700);
}

.certificates-header .action-buttons-wrapper div:nth-child(3) button {
    background-color: var(--primary-blue);
    border-color: var(--primary-blue);
}


/* Generate Certificate List Button */
.certificates-header .action-buttons-wrapper .p-card:nth-child(3) button:hover {
    background-color: var(--primary-red);
    border-color: var(--primary-blue)
}


/* Tag Certificates Button */
.certificates-header .action-buttons-wrapper .tag-certificates-button {
    background-color: var(--green-700);
    border-color: var(--green-700);
}

.certificates-header .action-buttons-wrapper .tag-certificates-button:hover {
    background-color: var(--primary-blue);
    border-color: var(--primary-blue);
}

.archive-button{
    flex: 1;                       
    min-height: 38px;              
    max-height: 38px;    
}


/* Delete Certificate Button */
.certificates-header .action-buttons-wrapper .delete-certificate-button {
    background-color: var(--primary-red);
    border-color: var(--primary-red);
}

.certificates-header .action-buttons-wrapper .delete-certificate-button:hover {
    background-color: var(--green-700);
    border-color: var(--green-700);
}

/* buttons ending */

.certificates-table-header.datatable-header-title {
    color: red;
    font-weight: bold;
    /* min-width: 20%; */
}


.certificate-download-link {

    color: var(--primary-blue);
    text-decoration: underline;

}


.certificate-download-link:hover {

    color: var(--primary-red);
}

.certificate-download-link:active {
    color: var(--primary-red);
}

.p-datatable .p-datatable-header .datatable-header-wrapper.certificates-header {

    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0rem 0.5rem;
    align-items: center;
}

.p-dialog.upload-card-dialog .p-dialog-header {
    padding: 1.5rem 0rem 0.5rem 2rem;
}

.p-dialog.upload-card-dialog .p-dialog-content {
    padding: 0rem 2rem 1.5rem;
}


.p-dialog.upload-card-dialog .header-description {
    margin: 0rem 0rem 1rem 0rem;
    font-weight: 600;
    opacity: 0.6;
}

.p-dialog.upload-card-dialog .p-dialog-header-icons {
    transform: translate(-20%, -50%);
}

.p-tag.status-tag {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    background-color: transparent;
}

.p-tag.status-tag.rejected {

    color: var(--primary-red);

}


.p-tag.status-tag.fulfilled {
    color: var(--green-700);

}

.p-tag.status-tag.pending {

    color: orange
}


.status-tag-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}


.status-tag-content span {
    font-weight: bold;
}

.status-tag-content .retry-button.p-button {
    border: none;
    background-color: var(--green-700);
    padding: 0.3rem;
    width: 2rem;
}

.status-tag-content .retry-button.p-button:hover {
    background-color: var(--primary-blue);
}

.p-dialog.add-certificate-dialog .header-description {
    margin: 0.5rem 0rem 0rem 0rem;
    font-weight: 600;
    font-size: medium;
    opacity: 0.6;
}

.certificates-view .tag {
    border-radius: 1em;
    background-color: #5499c7;
    color: white;
    text-align: center;
    padding: 0.5em 0.7em;
    font-weight: 700;
}

.certificates-view .tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: start;
}

/* .certificates-view .tag-remove-btn {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
    border: none;
    background: #808080;
    color: #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
} */
