.empty-template-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: large;
    height: 25vh;
}

.empty-template-container span {

    color: var(--primary-red);
}


.database-template-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: large;
    height: 25vh;
    cursor: pointer;
}

.item-template {
    display: flex;
    align-items: center;
    gap: 1rem
}

.item-template :global(.p-card .p-card-body), .item-template :global(.p-card .p-card-content) {
    padding: 0;


}


:global(.p-fileupload) :global(.p-fileupload-row)>.item-template {}

:global(.p-fileupload-row)>.item-template {
    width: 100%;
    justify-content: space-between;
    font-size: 1rem;
    font-family: var(--global-font);
}

:global(.p-fileupload-row) button {
    background-color: var(--primary-blue);
}




:global(.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon), :global(.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text) {
    color: rgb(68, 4, 27) !important;

}

.uploadcard {
    height: 100%;
    z-index: 2;
}

.uploadcard>[class="p-fileupload-buttonbar"] {
    height: 20%;
    font-size: medium;
    font-family: var(--global-font);
}

.uploadcard>[class="p-fileupload-buttonbar"]>:global(.p-button.p-fileupload-choose) {
    color: var(--primary-blue)
}

.uploadcard>[class="p-fileupload-content"] {
    max-height: 60%;
    /* justify-content: center;
  align-items: center; */
}

.uploadcard [class="p-fileupload-row"] {
    /* background-color: orange; */
}

.uploadcard :global(.p-progressbar .p-progressbar-value) {
    background: darkgreen;
}

:global(.upload-container) {
    width: 35vw;
}

:global(.upload-container) button {
    background-color: var(--primary-blue);
}