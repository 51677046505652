.add-certificate-dialog {
    max-width: 35vw;

}

.add-certificate-dialog.p-dialog .p-dialog-content {
    padding-top: 1.5rem
}



.certificates-header .action-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
}

.certificates-header .action-buttons-wrapper .refresh-certificates-button {

    background-color: var(--primary-blue);
    border: var(--primary-blue);
    padding: 0.5rem;
}

.certificates-header .action-buttons-wrapper .refresh-certificates-button:hover {

    background-color: var(--primary-red);
    border: var(--primary-red);
}

.certificates-header .action-buttons-wrapper button[aria-label*="Add new"] {
    background-color: var(--green-700);
    border-color: var(--green-700);
}

.delete-template-dialog.p-dialog .p-dialog-header {
    display: flex;
    gap: 0.5rem;
    padding: 1.5rem 1rem 0rem;

}

.p-dialog.upload-card-dialog .p-dialog-header {
    padding: 1.5rem 0rem 0.5rem 2rem;
}

.p-dialog.upload-card-dialog .p-dialog-content {
    padding: 0rem 2rem 1.5rem;
}


.p-dialog.upload-card-dialog .header-description {
    margin: 0rem 0rem 1rem 0rem;
    font-weight: 600;
    opacity: 0.6;
}

.p-dialog.delete-template-dialog .header-description {
    margin: 0.5rem 0rem 0rem 0rem;
    font-weight: 600;
    font-size: medium;
    opacity: 0.6;
}


.p-dialog.upload-card-dialog .p-dialog-header-icons {
    transform: translate(-20%, -50%);
}


.template-actions-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
    height: 100%;

}

.templates-datatable.p-datatable {

    font-size: 1.1rem;
}


.template-actions-wrapper div:first-child button, .template-actions-wrapper div:nth-child(2) button:hover {
    background-color: var(--primary-blue);
    border-color: var(--primary-blue)
}

.template-actions-wrapper div:nth-child(2) button {
    background-color: var(--primary-red);
    border-color: var(--primary-red)
}


.templates-datatable.p-datatable .p-datatable-header {

    padding: 1rem;
    display: flex;
    justify-content: flex-start;
}

.templates-datatable.p-datatable .p-datatable-header .datatable-header-wrapper {

    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0rem 0.5rem;
    align-items: center;
}

.templates-datatable.p-datatable .p-datatable-header .datatable-header-wrapper .datatable-header-title {
    display: flex;
    align-items: center;
}


.templates-datatable .p-datatable-header>div.p-card {

    width: fit-content;
}

.templates-deletion-dialog-footer .p-button {
    background-color: var(--primary-blue);
    border-color: var(--primary-blue);
}

.templates-deletion-dialog-footer .p-button:enabled:active,
.templates-deletion-dialog-footer .p-button:enabled:hover,
.templates-deletion-dialog-footer .p-button.p-button-text:enabled:hover {
    background-color: transparent;
    color: var(--primary-blue);
    border-color: var(--primary-blue);
}



.templates-deletion-dialog-footer .p-button.p-component.p-button-text {

    color: var(--primary-blue);
    background-color: transparent;
    border-color: transparent;
}